import { t } from '@zupr/i18n'
import dynamic from 'next/dynamic'
import Link from 'next/link'

import { useDomain } from '../../../context/domain'
import ErrorBoundary from '../../../shared/error/boundary'
import SiteMessages from '../site-messages'

import '../../../../scss/react/frontoffice/site-header.scss'
import '../../../../scss/react/frontoffice/site-top.scss'

const Logo = dynamic(() => import('./components/logo'), {
    ssr: false,
})

const SiteTop = () => {
    const { brand } = useDomain()
    return (
        <ErrorBoundary>
            <div className="site-top">
                <div className="site-header">
                    <div className="inner">
                        <div className="branding">
                            <Link href="/">
                                <a className="logo">
                                    <Logo />
                                </a>
                            </Link>
                        </div>
                        <Link href="/">
                            <a>{t('Terug naar %{brand}', { brand })}</a>
                        </Link>
                    </div>
                </div>
            </div>
            <SiteMessages />
        </ErrorBoundary>
    )
}

export default SiteTop
