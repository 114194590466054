import React, { useContext } from 'react'
import { t } from '@zupr/i18n'

import Trans from '../../../shared/components/trans'

import SiteTop from '../../components/top/simple'
import SiteContent from '../../components/site-content'

import AreaContext from '../../../context/domain'

import { ReactComponent as Logo } from '../../../../svg/logo.svg'
import { ReactComponent as ComingSoon } from '../../../../svg/coming-soon.svg'

import '../../../../scss/react/pages/placeholder.scss'

export const Zupr = () => (
    <div className="zupr-placeholder">
        <div className="zupr-placeholder-inner">
            <Logo />
            <h1>{t('Maak uw producten lokaal vindbaar', 'dutch')}</h1>
            <p>
                {t(
                    'Bent u een retailer, merk of winkelgebied, kijk dan op',
                    'dutch'
                )}{' '}
                <a href="https://www.zupr.io">{'https://www.zupr.io'}</a>{' '}
                {t('voor meer informatie.', 'dutch')}
            </p>
        </div>
    </div>
)

const Domain = () => {
    const {
        brand,
        openGraphTitle,
        openGraphDescription,
        host,
        shoppingAreaSlug,
    } = useContext(AreaContext)

    return (
        <React.Fragment>
            <div className="domain-placeholder">
                <div className="domain-placeholder-inner">
                    {openGraphDescription && (
                        <React.Fragment>
                            <h1>
                                {t(
                                    'Binnenkort komt hier een nieuw online warenhuis met het aanbod van lokale winkels!',
                                    'dutch'
                                )}
                            </h1>
                            <p>{openGraphDescription}</p>
                            <p>{openGraphTitle || brand}</p>
                        </React.Fragment>
                    )}

                    {!openGraphDescription && (
                        <React.Fragment>
                            <h1>{brand || host}</h1>
                            <p>
                                {t(
                                    'Binnenkort komt hier een nieuw online warenhuis met het aanbod van lokale winkels!',
                                    'dutch'
                                )}
                            </p>
                        </React.Fragment>
                    )}

                    <p>
                        <small>
                            <Trans
                                html
                                dutch
                                label={
                                    'Aanmelden als retailer? Kijk op <a href="https://zupr.io/nl/%{slug}">https://zupr.io/nl/%{slug}</a> voor meer informatie.'
                                }
                                values={{ slug: shoppingAreaSlug }}
                            />{' '}
                        </small>
                    </p>
                </div>
                <ComingSoon />
            </div>
        </React.Fragment>
    )
}

const LandingPage = () => {
    const { shoppingAreaSlug } = useContext(AreaContext)
    return (
        <React.Fragment>
            <SiteTop />
            <SiteContent>
                {shoppingAreaSlug === 'nederland' ? <Zupr /> : <Domain />}
            </SiteContent>
        </React.Fragment>
    )
}

export default LandingPage
