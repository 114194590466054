import { getProps } from '@zupr/next/helpers/props'
import { SiteProps } from '@zupr/types/next'
import Frontoffice from '@zupr/web/src/js/frontoffice/components/base'
import Providers from '@zupr/web/src/js/frontoffice/providers'
import Parked from '@zupr/web/src/js/frontoffice/screens/landing'
import { GetServerSideProps } from 'next'

export const getServerSideProps: GetServerSideProps<SiteProps> = async (context) => {
    return {
        props: await getProps(context),
    }
}

const Index = (props: SiteProps) => (
    <Providers {...props}>
        <Frontoffice>
            <Parked />
        </Frontoffice>
    </Providers>
)

export default Index
